<template>
	<div class="readme-article">
		<h1 id="班课">
			二、班课
		</h1>
		<h2 id="班级管理">1.班级管理</h2>
		<h3 id="班级列表">1.1班级列表</h3>
		<p>查看班级列表信息，显示开班结束时间以及学员人数等，可进行排课、查看详细等操作。</p>
		<p>
			<img src="@/assets/img/teach/2-1.png">
		</p>
		<h3 id="班级详细">1.2班级详细</h3>
		<p>查看班级详细，可修改班级名称，班主任，开课时间等信息。</p>
		<p>
			<img src="@/assets/img/teach/2-2.png">
		</p>
		<h3 id="班级排课">1.3班级排课</h3>
		<p>教师可对班级进行排课，按星期和时间进行选择，选择开课时间和结束时间等信息完成每周排课。</p>
		<p>
			<img src="@/assets/img/teach/2-3.png">
		</p>
		<h2 id="创建班级">2.创建班级</h2>
		<p>管理员可创建班级，填写班级名称，班级状态、班主任、开课时间等信息完成创建。</p>
		<p>
			<img src="@/assets/img/teach/2-4.png">
		</p>
		<h2 id="教室管理">3.教室管理</h2>
		<h3 id="教室列表">3.1教室列表</h3>
		<p>查看教室列表，显示教室名称、可容纳人数。</p>
		<p>
			<img src="@/assets/img/teach/2-5.png">
		</p>
		<h3 id="教室详细">3.2教室详细</h3>
		<p>查看教师详细，可修改教师名称以及容纳数量。</p>
		<p>
			<img src="@/assets/img/teach/2-6.png">
		</p>
		<h3 id="教室排课情况">3.3教室排课情况</h3>
		<p>查看教室排课情况，按日历表形式展示教室排课信息。</p>
		<p>
			<img src="@/assets/img/teach/2-7.png">
		</p>
		<h2 id="创建教室">4.创建教室</h2>
		<p>管理员可创建教室，填写教室名称、可容纳人数等信息完成创建。</p>
		<p>
			<img src="@/assets/img/teach/2-8.png">
		</p>
	</div>
</template>

<script>
	export default {
		name: "Staff2-1",
		data() {
			return {};
		},
		mounted() {},
		methods: {},
	};
</script>
<style scoped>
</style>
